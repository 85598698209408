<template>
  <div class="container-fluid" id="printArea">
    <CRow class="pb-3">
      <CCol>
        <h4 class="text-uppercase">Ward-wise Customer Report</h4>
      </CCol>
    </CRow>
    <CCard>
      <CRow class="d-print-none">
        <CCol cols="4" class="text-right">
          <CCol sm="12" class="text-right d-print-none">
            <a
              @click="exportReport()"
              class="print-btn mr-3"
              href="javascript:void(0)"
              ><i class="fa fa-file-excel-o fa-2x"></i
            ></a>

            <a @click="print()" class="print-btn" href="javascript:void(0)"
              ><i class="fa fa-print fa-2x"></i
            ></a>
          </CCol>
        </CCol>
      </CRow>

      <CRow>
        <CCol cols="12" class="text-center">
          <h4 class="tbl-txt">
            Number of Customers Based on wards, Total Customers:
            {{ grandTotal() }}
          </h4>
        </CCol>
      </CRow>

      <CRow>
        <CCol>
          <div class="table-responsive py-3">
            <table class="table table-border table-striped">
              <thead>
                <tr>
                  <th class="font-weight-bold">Ward</th>
                  <th class="font-weight-bold">Domestic</th>
                  <th class="font-weight-bold">Commercial</th>
                  <th class="font-weight-bold">Industrial</th>
                  <th class="font-weight-bold">Institutional</th>
                  <th class="font-weight-bold">Inactive</th>
                  <th class="font-weight-bold">Freedom Fighter</th>
                  <th class="font-weight-bold">Religious Institute</th>
                  <th class="font-weight-bold">DN15</th>
                  <th class="font-weight-bold">DN20</th>
                  <th class="font-weight-bold">DN25</th>
                  <th class="font-weight-bold">Total</th>
                </tr>
              </thead>

              <tbody v-if="wardCustomers.length">
                <tr v-for="ward in wardCustomers" :key="ward.ward_number">
                  <td>{{ ward.ward_number }}</td>
                  <td>{{ ward.Domestic ? ward.Domestic : 0 }}</td>
                  <td>{{ ward.Commercial ? ward.Commercial : 0 }}</td>
                  <td>{{ ward.Industrial ? ward.Industrial : 0 }}</td>
                  <td>{{ ward.Institutional ? ward.Institutional : 0 }}</td>
                  <td>{{ ward.Inactive ? ward.Inactive : 0 }}</td>
                  <td>
                    {{ ward["Freedom Fighter"] ? ward["Freedom Fighter"] : 0 }}
                  </td>
                  <td>
                    {{
                      ward["Religious Institute"]
                        ? ward["Religious Institute"]
                        : 0
                    }}
                  </td>
                  <td>{{ ward["DN15"] ? ward["DN15"] : 0 }}</td>
                  <td>{{ ward["DN20"] ? ward["DN20"] : 0 }}</td>
                  <td>{{ ward["DN25"] ? ward["DN25"] : 0 }}</td>
                  <td>{{ rowTotal(ward) }}</td>
                </tr>
              </tbody>

              <tbody v-else>
                <tr>
                  <td colspan="12">
                    <h5 class="text-center">No Data Available.</h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCol>
      </CRow>
    </CCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WardReport",
  data: () => {
    return {
      serverErrors: [],
      showDismissibleAlert: false,
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Reports", path:"/reports", isActive: false}, {text:"Ward-wise Customers", path:"", isActive: true}]
    };
  },

  computed: {
    ...mapGetters("Report", ["wardCustomers"])
  },
  mounted() {
    this.filter()
  },
  methods: {
    filter() {
      this.$store.dispatch("Report/wardWiseReport");
    },
    exportReport() {
      this.$store.dispatch("Report/exportWardReport");
    },
    rowTotal(ward) {
      let columns = ["DN15", "DN20", "DN25"];
      let total = 0;
      for (var i = 0; i < columns.length; i++) {
        total += ward[columns[i]] ? ward[columns[i]] : 0;
      }
      return total;
    },
    grandTotal() {
      let total = 0;
      for (var i = 0; i < this.wardCustomers.length; i++) {
        total += this.rowTotal(this.wardCustomers[i]);
      }
      return total;
    },
    print() {
      setTimeout(() => {
        this.printSetup();
      }, 50);
    },
    printSetup() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("printArea").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <title>Customers Based on wards</title>
          ${stylesHtml}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.addEventListener("load", () => {
        setTimeout(() => {
          WinPrint.print();
          WinPrint.close();
        }, 200);
      });
    },
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>

<style scoped>
.card {
    border: none;
    padding: 15px 15px 0;
}
</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
}
.print-btn {
  color: #000;
}
.tbl-txt {
  color: #6b6963;
  font-size: 16px;
}
table {
  tr {
    th {
      font-size: 16px;
    }
    td {
      font-size: 15px;
    }
    td,
    th {
      border: 1px solid #d8dbe0;
      color: #6b6963;
      text-align: center;
      padding: 0.5rem;
      font-weight: 600;
    }
  }
}
</style>
